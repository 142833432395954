import createElementChangeListener from "../../elementChangeListener.js";
import { fetchCustomizations } from "../../transport/customizations.js";
import loadContextData from "../../utils/loadContextData.js";
import onPostLcp from "../../utils/onPostLcp.js";
import { spawnTask } from "../../utils/spawnTask.js";

const CACHE_KEY = "_rm_smartlinks_";
const MAX_AGE = 1 * 60 * 60 * 1000;

async function loadFromCache() {
    const data = localStorage.getItem(CACHE_KEY);

    if (!data) {
        return null;
    }

    try {
        const parsed = JSON.parse(data);
        if (parsed && Array.isArray(parsed.customizations)) {
            return parsed;
        }
    } catch {
        return null;
    }

    return null;
}

async function saveOnCache(customizations) {
    localStorage.setItem(
        CACHE_KEY,
        JSON.stringify({
            customizations,
            age: Date.now(),
        })
    );
}

function doArraysHaveAnyOverlap(longerArray, shorterArray) {
    if (shorterArray.length > longerArray.length) {
        return doArraysHaveAnyOverlap(shorterArray, longerArray);
    }

    function isValueIncluded(value) {
        return shorterArray.includes(value);
    }

    return longerArray.some(isValueIncluded);
}

function shouldApplyCustomization(context, customization) {
    const { post } = context;
    const { sections, tags, id } = post;

    const {
        excluded_post_ids,
        excluded_section_ids,
        excluded_tags,
        included_section_ids,
        included_tags,
    } = customization;

    /**
     * If there is an exclude directive, we stop the process
     * only if the overlap exists
     */
    if (
        doArraysHaveAnyOverlap(excluded_section_ids, sections) ||
        doArraysHaveAnyOverlap(excluded_tags, tags) ||
        doArraysHaveAnyOverlap(excluded_post_ids, [id])
    ) {
        return false;
    }

    /**
     * If there is an include directive, we return whatever is the result of the overlap
     */
    if (included_section_ids.length > 0) {
        return doArraysHaveAnyOverlap(included_section_ids, sections);
    }

    if (included_tags.length > 0) {
        return doArraysHaveAnyOverlap(included_section_ids, tags);
    }

    /**
     * Apply by default
     */
    return true;
}

function applyAttribute(element, attributePair) {
    const [key, value] = attributePair;

    function addClassToElement(class_) {
        try {
            element.classList.add(class_);
        } catch (error) {
            console.error(error);
        }
    }

    try {
        if (key === "class") {
            const classes = value.split(" ");
            classes.forEach(addClassToElement);
        } else {
            element.setAttribute(key, value);
        }
    } catch (error) {
        console.error(error);
    }

    return element;
}

function applyCustomization(element, customization) {
    const { extra } = customization;

    Object.entries(extra.attributes || {}).reduce(applyAttribute, element);
}

function attachCustomization(customization) {
    async function onElement(element) {
        await spawnTask();
        const parentPost = element.closest("article[elid]");
        if (!parentPost) {
            return;
        }

        const context = loadContextData(`#post-context-${parentPost.getAttribute("elid")}`);

        if (Object.keys(context).length === 0) {
            return;
        }

        const should = shouldApplyCustomization(context, customization);

        if (!should) {
            return;
        }

        applyCustomization(element, customization);
    }

    try {
        createElementChangeListener(customization.find_phrase, onElement);
    } catch (error) {
        console.error(error);
    }
}

async function attachCustomizations(customizations) {
    customizations.forEach(attachCustomization);
}

async function fetchAndSave() {
    const replacements = await fetchCustomizations();
    await saveOnCache(replacements).catch(console.error);
    return replacements;
}

async function updateDataIfStale(age) {
    if (!age || Date.now() - age > MAX_AGE) {
        return fetchAndSave();
    }
}

function makeUpdateIfStale(age) {
    function cb() {
        updateDataIfStale(age).catch(console.error);
    }

    return cb;
}

function fetchAndAttach() {
    fetchAndSave().then(attachCustomizations).catch(console.error);
}

async function loadAndAttach() {
    const data = await loadFromCache();
    if (data) {
        onPostLcp(makeUpdateIfStale(data.age));
        attachCustomizations(data.customizations).catch(console.error);
    } else {
        onPostLcp(fetchAndAttach);
    }
}

export function setUpSmartLinkCustomizations(context) {
    if (context.settings.engine.useSmartLinks2) {
        loadAndAttach().catch(console.error);
    }
    return context;
}
